import React, { useState } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Button, Form, Modal } from 'react-bootstrap';

import './Consent.css';

const STATUS_ID_GDPR = '11291';

const schema = yup.object({
  consentPrivacy: yup
    .boolean()
    .required('Er moet toestemming gegeven worden.')
    .oneOf([true], 'Er moet toestemming gegeven worden.'),
  consentMarketing: yup.boolean(),
});

function Consent({
  data,
  onSubmit,
  buttonTitle,
}: {
  data: any;
  onSubmit: any;
  buttonTitle: string;
}) {
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  return (
    <>
      <h3>Toestemmingsformulier voor het gebruik van persoonlijke gegevens</h3>
      {data.statusId === STATUS_ID_GDPR && (
        <p>
          Beste {data.firstName} {data.lastName}
        </p>
      )}
      <p>
        De bescherming van uw persoonsgegevens is een prioriteit voor Conessence. Opdat Conessence
        jou kan contacteren voor jobs die beantwoorden aan jouw profiel en die volledig aansluiten
        bij jouw criteria, hebben we jouw toestemming nodig. We leggen je graag uit wat dit voor u
        betekent in de{' '}
        <button
          onClick={() => setShowPrivacyModal(true)}
          className="btn btn-link"
          style={{ padding: 0 }}
        >
          privacyverklaring
        </button>
        .
      </p>
      <p>Kruis hieronder aan wat uw voorkeuren zijn en mis geen enkele opportuniteit:</p>
      <Formik
        validationSchema={schema}
        onSubmit={onSubmit}
        initialValues={{
          consentPrivacy: false,
          consentMarketing: false,
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ handleSubmit, handleChange, values, errors, isSubmitting }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Check
                required
                name="consentPrivacy"
                label="Privacyverklaring gelezen &amp; begrepen en ik geef mijn toestemming aan Conessence en aan de andere vennootschappen van Conessence voor de verwerking van mijn persoonsgegevens voor een vaste job, tijdelijke opdracht en andere jobopportuniteiten; voor de evaluatie van profielen en professionele vaardigheden (zoals beschreven in de Privacyverklaring) door Conessence en/of door met haar verbonden ondernemingen;"
                onChange={handleChange}
                isInvalid={!!errors.consentPrivacy}
                feedback={errors.consentPrivacy}
                checked={values.consentPrivacy}
                id="consentPrivacy"
              />
            </Form.Group>
            <Form.Group>
              <Form.Check
                name="consentMarketing"
                label="Ik geef mijn toestemming om mijn persoonsgegevens te gebruiken om informatie en marketingcampagnes te ontvangen (zoals interactieve communicatie, evenementen van Conessence, marktstudies en statistische studies verstuurd via e-mail, sms zoals beschreven in de Privacyverklaring);"
                onChange={handleChange}
                isInvalid={!!errors.consentMarketing}
                feedback={errors.consentMarketing}
                checked={values.consentMarketing}
                id="consentMarketing"
              />
            </Form.Group>
            <p style={{ fontSize: '12px', fontStyle: 'italic' }}>
              Als u meer informatie wenst over de verwerking van uw persoonsgegevens of de
              uitoefening van uw rechten, kan u contact opnemen met Conessence zoals vermeld bij de
              contactgegevens opgenomen in de privacyverklaring.
            </p>
            <Button style={{ marginTop: '20px' }} type="submit" disabled={isSubmitting}>
              {buttonTitle}
            </Button>
          </Form>
        )}
      </Formik>
      <Modal show={showPrivacyModal} onHide={() => setShowPrivacyModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Privacyverklaring</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <p className="c4">
              <span className="c2">
                Bescherming van persoonsgegevens
                <br />
              </span>
            </p>
            <p className="c4">
              <span className="c3">
                Dit is de privacyverklaring voor de verwerking van uw persoonsgegevens:{' '}
              </span>
              <span className="c2">Conessence</span>
              <span className="c3">&nbsp;</span>
              <span className="c2">Financial Recruitment BV</span>
              <span className="c3">, met zetel te 9000 Gent, Kortrijksesteenweg 1023, </span>
              <span className="c10">KBO 0681.915.</span>
              <span className="c3">
                443 en haar gelieerde vennootschappen HR Essentials BV, Conessence Interim
                Management BV, Conessence Executive Search BV, allen samen hierna
                &ldquo;CONESSENCE&rdquo; genoemd.
              </span>
              <span className="c10">
                &nbsp;
                <br />
              </span>
            </p>
            <p className="c4">
              <span className="c3">
                Wij beschouwen privacy als een van uw meest fundamentele rechten en handelen steeds
                in overeenstemming met de Verordening van 27 april 2016 betreffende de bescherming
                van natuurlijke personen in verband met de verwerking van persoonsgegevens en
                betreffende het vrij verkeer van die gegevens en tot intrekking van Richtlijn
                95/46/EG (hierna: de &quot;Verordening&quot;) en met onze deontologie.{' '}
              </span>
            </p>
            <p className="c4">
              <span className="c3">
                Wij verwerken uw persoonsgegevens in overeenstemming met deze Privacyverklaring. De
                Privacyverklaring is van toepassing op alle relaties tussen ons enerzijds en onze
                klanten, prospecten en partners anderzijds.{' '}
              </span>
            </p>
            <p className="c4">
              <span className="c3">
                <br />
                Op deze pagina laten we u weten welke gegevens we verzamelen als u onze website
                gebruikt, waarom we deze gegevens verzamelen en hoe we hiermee uw gebruikservaring
                verbeteren. Deze Privacy Policy kan regelmatig worden aangepast of geactualiseerd.
                Wij verzoeken u dan ook vriendelijk om deze Privacy Policy geregeld te raadplegen,
                zodat u steeds over de meest recente versie beschikt.{' '}
              </span>
            </p>
            <p className="c4 c8">
              <span className="c2"></span>
            </p>
            <p className="c4 c8">
              <span className="c2"></span>
            </p>
            <p className="c4 c8">
              <span className="c2"></span>
            </p>
            <ol className="c1 lst-kix_list_2-0 start" start={1}>
              <li className="c4 c12">
                <span className="c2">Welke persoonsgegevens verwerken wij en waarom? </span>
              </li>
            </ol>
            <p className="c4">
              <span className="c3">
                Wanneer u via onze websites of op een andere manier solliciteert voor een vacature,
                vragen wij uw goedkeuring voor de verwerking van uw persoonlijke gegevens om het
                voor ons mogelijk te maken u te helpen bij het vinden van een geschikte job. <br />
              </span>
            </p>
            <p className="c4">
              <span className="c3">
                A.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Wanneer u onze{' '}
              </span>
              <span className="c2">websites </span>
              <span className="c3">gebruikt, verzamelen wij: </span>
            </p>
            <p className="c13">
              <span className="c3">
                - technische informatie nopens uw apparaat (IP-adres, browsertype, geografische
                locatie en besturingssysteem)
              </span>
            </p>
            <p className="c4 c8">
              <span className="c3"></span>
            </p>
            <p className="c4">
              <span className="c3">
                A.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Wanneer u een{' '}
              </span>
              <span className="c2">contactformulier </span>
              <span className="c3">
                op onze websites invult of contact met ons opneemt via e-mail, telefoon, fax of een
                sociaal medium, kunnen wij de volgende zaken verzamelen:{' '}
              </span>
            </p>
            <p className="c0">
              <span className="c3">- naam, e-mailadres, postadres, telefoonnummer </span>
            </p>
            <p className="c4">
              <span className="c3">- uw huidige werkgever en uw huidige functie </span>
            </p>
            <p className="c4">
              <span className="c3">- informatie over uw opleiding en uw werkervaring </span>
            </p>
            <p className="c9">
              <span className="c3">- uw curriculum vitae, motivatiebrief </span>
            </p>
            <p className="c4">
              <span className="c3">- de loggegevens van uw bericht (datum, tijdstip) </span>
            </p>
            <p className="c4 c8">
              <span className="c3"></span>
            </p>
            <p className="c4">
              <span className="c3">
                A.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Wanneer u door ons wordt
                beschouwd{' '}
              </span>
              <span className="c2">als kandidaat </span>
              <span className="c3">kunnen wij verzamelen: </span>
            </p>
            <p className="c0">
              <span className="c3">- notities gemaakt lopende de sollicitatieprocedure </span>
            </p>
            <p className="c0">
              <span className="c3">- profielnaam en wachtwoord voor ons online portaal </span>
            </p>
            <p className="c0">
              <span className="c3">
                - evaluatieformulieren, aanbevelingen, resultaten van test en assessments{' '}
              </span>
            </p>
            <p className="c0">
              <span className="c3">- loonfiches </span>
            </p>
            <p className="c0">
              <span className="c3">- externe en interne payroll informatie </span>
            </p>
            <p className="c0">
              <span className="c3">
                - de loggegevens van het gesprek (datum, tijdstip, met wie u communiceert,&hellip;){' '}
              </span>
            </p>
            <p className="c4">
              <span className="c3">- andere persoonlijke gegevens die u aan ons verstrekt. </span>
            </p>
            <p className="c4 c8">
              <span className="c3"></span>
            </p>
            <p className="c4">
              <span className="c3">
                A.4. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CONESSENCE verwerkt bijkomend
                soms publieke gegevens, namelijk gegevens die zich bevinden in publieke databanken
                zoals de kruispuntbank der ondernemingen, gegevens die u zelf publiek heeft gemaakt
                op een website, gegevens die algemeen bekend zijn of in de pers zijn verschenen.{' '}
                <br />
              </span>
            </p>
            <p className="c4">
              <span className="c3">
                A.5. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Daarnaast maken wij voor onze
                website ook gebruik van cookies.
                <br />
              </span>
            </p>
            <p className="c4 c6">
              <span className="c2">
                B. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Doeleinden voor de verwerking{' '}
              </span>
            </p>
            <p className="c4">
              <span className="c3">
                Wij verwerken uw persoonlijke gegevens om u op een persoonlijke en effici&euml;nte
                manier de gevraagde informatie, producten en diensten te kunnen aanbieden. <br />
              </span>
            </p>
            <p className="c4">
              <span className="c3">
                B.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Wanneer{' '}
              </span>
              <span className="c2">u op zoek bent naar een job</span>
              <span className="c3">, verwerken wij uw persoonlijke gegevens om: </span>
            </p>
            <p className="c0">
              <span className="c3">- u te helpen een geschikte job te vinden </span>
            </p>
            <p className="c0">
              <span className="c3">
                - met u te communiceren over openstaande functies en de voortgang van eventuele
                sollicitaties{' '}
              </span>
            </p>
            <p className="c0">
              <span className="c3">
                - uw gegevens te bezorgen aan geschikte toekomstige werkgevers, op voorwaarde dat u
                ons daarvoor specifiek de toestemming hebt gegeven{' '}
              </span>
            </p>
            <p className="c0">
              <span className="c3">
                - u meldingen te bezorgen over openstaande functies waarvan wij denken dat die u
                zouden kunnen interesseren{' '}
              </span>
            </p>
            <p className="c0">
              <span className="c3">
                - u te helpen en te adviseren tijdens uw zoektocht naar een nieuwe job{' '}
              </span>
            </p>
            <p className="c0">
              <span className="c3">
                - u informatie te geven over de algemene trends op de vacaturemarkt{' '}
              </span>
            </p>
            <p className="c4">
              <span className="c3">
                - u feedback te vragen over onze diensten en onze verplichtingen aan onze klanten te
                kunnen naleven wanneer u een job krijgt aangeboden.{' '}
              </span>
            </p>
            <p className="c4 c8">
              <span className="c3"></span>
            </p>
            <p className="c4">
              <span className="c3">
                B.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Als u ons als{' '}
              </span>
              <span className="c2">opdrachtgever </span>
              <span className="c3">
                vraagt om met u samen te werken, verwerken wij uw persoonlijke gegevens om:{' '}
              </span>
            </p>
            <p className="c0">
              <span className="c3">- u de gevraagde dienstverlening te kunnen bieden </span>
            </p>
            <p className="c0">
              <span className="c3">
                - u verslagen te bezorgen over het werk dat wij voor u uitvoeren{' '}
              </span>
            </p>
            <p className="c4">
              <span className="c3">
                - u advies te geven aangaande relevante diensten en producten{' '}
              </span>
            </p>
            <p className="c4">
              <span className="c3">
                - u informatie te geven over de algemene trends op de vacaturemarkt{' '}
              </span>
            </p>
            <p className="c4">
              <span className="c3">- u feedback te vragen over onze diensten. </span>
            </p>
            <p className="c4 c8">
              <span className="c3"></span>
            </p>
            <p className="c4">
              <span className="c3">
                B.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Daarnaast verwerken wij uw
                persoonlijke gegevens om wettelijke verplichtingen na te leven of om te beantwoorden
                aan eventuele redelijke vragen van bevoegde ordehandhavingsdiensten of
                -vertegenwoordigers, het gerecht en overheidsinstanties of - organisaties, waaronder
                bevoegde gegevensbeschermingsinstanties. <br />
                <br />
              </span>
            </p>
            <p className="c4 c6">
              <span className="c2">
                C. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Rechtsgronden voor de verwerking{' '}
              </span>
            </p>
            <p className="c4">
              <span className="c3">C.1. Aangezien u als </span>
              <span className="c2">kandidaat </span>
              <span className="c3">of als </span>
              <span className="c2">opdrachtgever </span>
              <span className="c3">
                beroep wenst te doen op onze diensten verwerken wij de volgende persoonsgegevens:{' '}
              </span>
            </p>
            <p className="c0">
              <span className="c3">- naam, e-mailadres, postadres, telefoonnummer </span>
            </p>
            <p className="c0">
              <span className="c3">- profielnaam en wachtwoord </span>
            </p>
            <p className="c0">
              <span className="c3">- uw huidige werkgever en uw huidige functie </span>
            </p>
            <p className="c0">
              <span className="c3">- informatie over uw opleiding en uw werkervaring </span>
            </p>
            <p className="c0">
              <span className="c3">- uw curriculum vitae, motivatiebrief </span>
            </p>
            <p className="c0">
              <span className="c3">- notities gemaakt lopende de sollicitatieprocedure </span>
            </p>
            <p className="c0">
              <span className="c3">
                - evaluatieformulieren, aanbevelingen, resultaten van test en assessments{' '}
              </span>
            </p>
            <p className="c0">
              <span className="c3">- loonfiches </span>
            </p>
            <p className="c0">
              <span className="c3">- externe en interne payroll informatie </span>
            </p>
            <p className="c4">
              <span className="c3">- andere persoonlijke gegevens die u aan ons verstrekt </span>
            </p>
            <p className="c4 c8">
              <span className="c3"></span>
            </p>
            <p className="c4">
              <span className="c3">
                C.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Wij verwerken bijkomend de
                volgende persoonsgegevens:{' '}
              </span>
            </p>
            <p className="c13">
              <span className="c3">
                - technische informatie nopens uw apparaat, zoals uw IP-adres, browsertype,
                geografische locatie en besturingssysteem{' '}
              </span>
            </p>
            <p className="c13">
              <span className="c3">- de technische details van het bericht (datum, tijdstip) </span>
            </p>
            <p className="c4">
              <span className="c3">
                - de technische details van het gesprek (datum, tijdstip, met wie u
                communiceert,&hellip;){' '}
              </span>
            </p>
            <p className="c4 c8">
              <span className="c3"></span>
            </p>
            <p className="c4">
              <span className="c3">
                Op basis van het legitieme belang van Conessence, teneinde haar websites en
                dienstverlening voortdurend te monitoren, bij te sturen en zodoende te verbeteren.{' '}
                <br />
              </span>
            </p>
            <p className="c4">
              <span className="c3">
                C.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Wij verwerken persoonsgegevens
                bijkomend in het kader van direct marketing, indien u hiertoe uw toestemming hebt
                gegeven.{' '}
              </span>
            </p>
            <p className="c4">
              <span className="c3">
                Tegen deze toestemming kunt u ten allen tijde uw bezwaar laten gelden, zonder enige
                motivering. U kunt hiertoe contact opnemen via info@conessence.com.
              </span>
            </p>
            <hr style={{ pageBreakBefore: 'always', display: 'none' }} />
            <p className="c4 c6">
              <span className="c2">
                D. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Doorgifte aan derden{' '}
              </span>
            </p>
            <p className="c4">
              <span className="c3">
                Conessence zal uw gegevens nooit doorgeven aan derden, tenzij aan haar gelieerde
                vennootschappen of aan opdrachtgevers in het kader van de uitvoering van haar
                diensten, tenzij dit noodzakelijk is voor de technische ondersteuning of wanneer zij
                hiertoe gehouden is op grond van een wettelijke bepaling of een rechterlijke
                uitspraak. <br />
              </span>
            </p>
            <p className="c4">
              <span className="c3">
                Conessence doet een beroep op derden om u onze websites en diensten te kunnen
                aanbieden en om uw persoonlijke gegevens te verwerken in onze naam. Wij werken
                bijvoorbeeld samen met betrouwbare derden voor het uitvoeren van onderzoek en het
                verzorgen van e-maildiensten. Wanneer u op zoek bent naar een job, werken wij
                mogelijk samen met derden die in onze naam taken uitvoeren, zoals het controleren
                van referenties, het uitvoeren van psychometrische evaluaties en het testen van
                vaardigheden. <br />
              </span>
            </p>
            <p className="c4">
              <span className="c3">
                Deze derden mogen uw persoonlijke gegevens alleen in onze naam en na onze
                uitdrukkelijke schriftelijke toestemming en conform de hierboven geschetste
                doeleinden verwerken.{' '}
              </span>
            </p>
            <p className="c4">
              <span className="c3">
                Wij garanderen dat alle derde verwerkers zorgvuldig worden geselecteerd en verplicht
                zijn om de veiligheid en integriteit van uw persoonlijke gegevens te vrijwaren.{' '}
                <br />
              </span>
            </p>
            <p className="c4 c6">
              <span className="c2">
                E. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Beveiliging van uw
                persoonsgegevens{' '}
              </span>
            </p>
            <p className="c4">
              <span className="c3">
                Conessence garandeert een kwalitatief niveau van gegevens- en databeveiliging, en
                dit door het nemen van alle noodzakelijke &amp; redelijke technische en
                organisatorische maatregelen. Wij streven ernaar om deze maatregelen op geregelde
                tijdstippen te evalueren en indien nodig bij te sturen.{' '}
              </span>
            </p>
            <p className="c4">
              <span className="c3">
                Conessence aanvaardt dan ook geen aansprakelijkheid, noch voor directe noch voor
                indirecte schade dat voortvloeit uit een onrechtmatige verwerking van een derde van
                uw persoonsgegevens. <br />
              </span>
            </p>
            <p className="c4 c6">
              <span className="c2">
                F. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bewaartermijn{' '}
              </span>
            </p>
            <p className="c4">
              <span className="c3">
                Conessence zal uw persoonsgegevens bewaren en verwerken voor een periode die
                noodzakelijk is voor de uitvoering van de overeenkomst en in functie met de
                doeleinden van de verwerking, zoals hierboven omschreven. Uw persoonsgegevens worden
                zodoende als volgt bewaard: voor de duur van de diensten overeenkomstig de hierboven
                omschreven doeleinden (actieve bewaarperiode) en van zodra er geen sprake meer is
                van enig contact, gedurende een passieve bewaarperiode van 5 jaar. <br />
              </span>
            </p>
            <p className="c4 c6">
              <span className="c2">
                G.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Uw rechten{' '}
              </span>
            </p>
            <p className="c4">
              <span className="c3">
                Bij de verwerking van uw persoonsgegevens hebt u verschillende rechten. Wanneer wij
                voor een verwerking om toestemming vragen, dan kan u die toestemming op elk moment
                weer intrekken. Als u een van deze onderstaande rechten wil uitoefenen, kan u ons
                steeds contacteren op volgend e- mailadres:{' '}
              </span>
              <span className="c11">
                <a className="c7" href="mailto:info@conessence.com">
                  info@conessence.com
                </a>
              </span>
              <span className="c3">
                <br />{' '}
              </span>
            </p>
            <p className="c4">
              <span className="c3">- Recht op toegang en inzage </span>
            </p>
            <p className="c4">
              <span className="c3">- Recht op verbetering, verwijdering en beperking </span>
            </p>
            <p className="c4">
              <span className="c3">- Recht op het wissen van uw gegevens </span>
            </p>
            <p className="c4">
              <span className="c3">
                - Recht op ongemotiveerd en kosteloos bezwaar tegen direct marketing{' '}
              </span>
            </p>
            <p className="c4">
              <span className="c3">- Recht op intrekking van uw toestemming </span>
            </p>
            <p className="c4">
              <span className="c3">
                - Recht op overdraagbaarheid van uw gegevens <br />
              </span>
            </p>
            <p className="c4">
              <span className="c3">
                Wij proberen uw verzoek steeds te beantwoorden, en indien mogelijk te behandelen,
                binnen een maand na ontvangst.{' '}
              </span>
            </p>
            <p className="c4">
              <span className="c3">
                Om uw aanvraag concreet en correct te behandelen vragen wij om zo specifiek mogelijk
                te zijn bij het richten van uw aanvraag. Daarnaast moeten wij de identiteit van de
                verzoeker kunnen nagaan. Gelieve dan ook een kopie van uw identiteitskaart te voegen
                bij uw verzoek. <br />
              </span>
            </p>
            <p className="c4 c6">
              <span className="c2">
                H. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Vragen of klachten
              </span>
              <span className="c3">
                &nbsp;
                <br />
                Indien u verdere vragen of klachten hebt onze diensten en uw privacy of waarop wij
                uw persoonsgegevens verwerken, kan u steeds terecht op volgend e-mailadres:{' '}
              </span>
              <span className="c11">
                <a className="c7" href="mailto:info@conessence.com">
                  info@conessence.com
                </a>
              </span>
              <span className="c3">. </span>
            </p>
            <p className="c4 c8">
              <span className="c14"></span>
            </p>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowPrivacyModal(false)}>
            Sluiten
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Consent;
