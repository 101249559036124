import React from 'react';
import { Col, Container, Jumbotron, Row } from 'react-bootstrap';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { ReactComponent as Logo } from './logo.svg';

import Start from './Start';

function App() {
  return (
    <Router>
      <Container className="mt-4">
        <Row>
          <Col>
            <h2>
              <Logo />
            </h2>
            <Jumbotron>
              <Switch>
                <Route path="/start">
                  <Start />
                </Route>
                <Route path="/">Ongeldige link</Route>
              </Switch>
            </Jumbotron>
          </Col>
        </Row>
      </Container>
    </Router>
  );
}

export default App;
