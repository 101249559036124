import React, { useState } from 'react';
import axios from 'axios';
import Consent from './Consent';
import { STATUS_ID_TEST } from './Start';
import TestPart from './TestPart';

const API_HOST = process.env.REACT_APP_API_HOST;

function Test({ data, refetch }: { data: any; refetch: any }) {
  const [consentGranted, setConsentGranted] = useState<null | { [key: string]: boolean }>(null);

  const onSubmitGrant = async (values: { [key: string]: boolean }) => {
    if (data.statusId === STATUS_ID_TEST) {
      setConsentGranted(values);
    } else {
      await axios.put(`${API_HOST}/${data.candidateId}/${data.testId}`, values);
      await refetch();
    }
  };

  const onSubmitTest = async (answerValues: { [key: string]: string }) => {
    const answers: { questionId: string; answerId: string }[] = Object.keys(answerValues).map(
      (questionId: string) => ({
        questionId,
        answerId: answerValues[questionId],
      })
    );
    await axios.put(`${API_HOST}/${data.candidateId}/${data.testId}`, {
      answers,
      ...consentGranted,
    });
    await refetch();
  };

  const consentButtonTitle =
    data.statusId === STATUS_ID_TEST ? 'Toestemming geven en test starten' : 'Toestemming geven';

  return !consentGranted ? (
    <Consent data={data} onSubmit={onSubmitGrant} buttonTitle={consentButtonTitle} />
  ) : (
    <TestPart onSubmit={onSubmitTest} testData={data.test} />
  );
}

export default Test;
