import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Button, Form } from 'react-bootstrap';
import * as yup from 'yup';

interface answer {
  id: string;
  title: string;
  titleHtml?: string;
}

interface MultipleChoiceQuestion {
  id: string;
  title: string;
  answers: answer[];
}

interface TestList {
  title: string;
  questions: MultipleChoiceQuestion[];
}

// const testData: TestList[] = [
//   {
//     title: 'Aftrekbare btw - Duid het juiste aftrekbare percentage aan',
//     questions: [
//       {
//         id: '3a0bd89d-e52d-4a65-8f26-95bebf257605',
//         title: 'Aankoop relatiegeschenk voor 49,50 EUR',
//         answers: [
//           {
//             id: '466d5086-9f38-42ee-829f-76d446e44793',
//             title: '0%',
//           },
//           {
//             id: '97e6593f-bdeb-4c3c-808d-e2ca6a07f436',
//             title: '50%',
//           },
//           {
//             id: 'ba408439-5857-4edc-84a9-5f1c9c2747ea',
//             title: '100%',
//           },
//         ],
//       },
//       {
//         id: '6fc0b0a1-8e45-4a46-a30f-aef74a0d26e5',
//         title: 'Aankoop beroepsspecifieke werkkledij',
//         answers: [
//           {
//             id: '7eb19f5c-c654-4a24-b3cc-0d7470d59e8c',
//             title: '0%',
//           },
//           {
//             id: '2efa4059-768c-4a4f-b422-b1765a898689',
//             title: '50%',
//           },
//           {
//             id: 'e5f4180b-fc52-4801-9267-594e1069e845',
//             title: '100%',
//           },
//         ],
//       },
//       {
//         id: '3af5e807-7430-4a23-bdca-d01709f61b1f',
//         title: 'Aankoop relatiegeschenk sterke drank ter waarde van 40,00 EUR',
//         answers: [
//           {
//             id: '34cea24d-d636-467b-847f-97203993b042',
//             title: '0%',
//           },
//           {
//             id: '78cb9f0f-48ed-49b3-b458-ce6a69cc1b24',
//             title: '50%',
//           },
//           {
//             id: 'f759740e-1f41-4544-a0ac-fdcd744d9048',
//             title: '100%',
//           },
//         ],
//       },
//       {
//         id: '97f5e5f2-8f85-4f1f-8b34-82457fae0772',
//         title: 'Aankoop personenwagen 100% beroepsmatig',
//         answers: [
//           {
//             id: 'a869631a-cb23-406f-abf1-b39a6254bc10',
//             title: 'Maximaal 50%',
//           },
//           {
//             id: '2c35e4e3-52d8-47e9-aece-afd5d2081666',
//             title: 'Maximaal 35%',
//           },
//           {
//             id: 'a2b7a11c-abe4-4dc7-85a3-7023a1dafc3c',
//             title: 'Maximaal 100%',
//           },
//         ],
//       },
//       {
//         id: 'b862b2c8-029f-4bbe-8cbe-74d1c630a0d0',
//         title: 'Taxidiensten voor beroepsmatig gebruik',
//         answers: [
//           {
//             id: '57c52a2a-0d16-4514-bd01-6e2885d3fb02',
//             title: '0%',
//           },
//           {
//             id: '372565f6-eb12-4fcc-8026-31bb9dc8468c',
//             title: '50%',
//           },
//           {
//             id: '3998a1ff-3d90-4436-bf41-570afcbdc1fa',
//             title: '100%',
//           },
//         ],
//       },
//       {
//         id: '175ab3fd-1b4c-476c-9aab-211afb5cebd7',
//         title: 'Receptie voor eigen personeel (niet verzorgd door eigen mensen)',
//         answers: [
//           {
//             id: '31c1d63d-3f56-4e47-9e8f-106d32e0caba',
//             title: '0%',
//           },
//           {
//             id: 'b95ea809-2bd9-49e2-9480-505062b42fd7',
//             title: '75%',
//           },
//           {
//             id: '4c3d527b-dea1-4c8b-aff8-c2c5dd70d226',
//             title: '100%',
//           },
//         ],
//       },
//       {
//         id: '5f3a1664-138b-4c10-81b4-dc2ae63a6573',
//         title: 'Telefoonkosten voor eigen personeel (factuur op naam van personeelslid)',
//         answers: [
//           {
//             id: 'ad65b296-35b4-43ce-a466-e734ca16d0c6',
//             title: '0%',
//           },
//           {
//             id: 'f179d45e-36bb-449c-bfdb-5c7f1c7962c7',
//             title: '50%',
//           },
//           {
//             id: '3c8ae46f-ea80-4d87-9e27-70ff3837b557',
//             title: '100%',
//           },
//         ],
//       },
//       {
//         id: '769148d3-0363-4b7c-8074-309a19ff3524',
//         title: 'Bloemen voor de versiering van het bedrijfslokaal',
//         answers: [
//           {
//             id: '8ecf7a4b-1358-4f73-a7d5-953c2f9e9c95',
//             title: '0%',
//           },
//           {
//             id: 'b803b403-0a69-4b77-bcbb-4ab5471fd2f2',
//             title: '50%',
//           },
//           {
//             id: '8b9e3259-6dc0-4dc2-89ff-b9ff05fb99a8',
//             title: '100%',
//           },
//         ],
//       },
//       {
//         id: 'db9ffe9e-bb4c-4ca5-86bf-88fcd7e12de3',
//         title: 'Aankoop van een kunstwerk als beleggingsobject',
//         answers: [
//           {
//             id: '815ca93f-6100-4430-80a1-fb9d77a8d449',
//             title: '0%',
//           },
//           {
//             id: 'bb26b9ac-4c93-4e58-a0dc-69a343d05b4b',
//             title: '50%',
//           },
//           {
//             id: '45836038-431f-4f03-97f9-a3e9d348c50a',
//             title: '100%',
//           },
//         ],
//       },
//       {
//         id: '707d1a34-91de-4967-8e58-08c0cc7ceaef',
//         title: 'Aankoop van een laptop voor het werk',
//         answers: [
//           {
//             id: '6bba39b4-e97f-40db-8bfb-640be54ea050',
//             title: '0%',
//           },
//           {
//             id: 'fe46de03-1799-47b6-868d-0b5808ff4b23',
//             title: '50%',
//           },
//           {
//             id: '616aa77e-9d13-4eef-9b45-50421589e9aa',
//             title: '100%',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Kennis algemene boekhouding',
//     questions: [
//       {
//         id: '8a16116c-b2f2-46fa-aa7d-70a4249a5448',
//         title:
//           'Conessence heeft op 01/01/2020 een personenwagen aangekocht ter waarde van 25.000,00 EUR excl. btw. Deze wagen wordt 80% beroepsmatig gebruikt. We schrijven deze wagen lineair af op 5 jaar. Het bedrag van de eerste afschrijving is:',
//         answers: [
//           {
//             id: '07a08df5-f8dd-497c-a74c-f7cc6aa5a788',
//             title: '5.840,00 EUR',
//           },
//           {
//             id: '09f301ef-4212-45f2-b4ca-7f45dbab2fa5',
//             title: '5.525,00 EUR',
//           },
//           {
//             id: 'a20c2e06-0f48-4a63-ab1c-f976fd724289',
//             title: '5.000,00 EUR',
//           },
//           {
//             id: 'e594f2b7-546f-43c2-9a30-db434e486b66',
//             title: '5.367,50 EUR',
//           },
//         ],
//       },
//       {
//         id: '5a93dd78-3423-43ec-9eea-f1168e77e22e',
//         title:
//           'Aan één van de appartementen die voor privébewoning wordt verhuurd door de patrimoniumvennootschap en die periodieke btw-aangiften indient, worden schilderwerken uitgevoerd. We ontvangen hiervoor een factuur van 10.000,00 EUR met toepassing van ‘btw verlegd’.',
//         answers: [
//           {
//             id: '99fa4f60-a55b-4ac7-9369-54da555ebfbd',
//             title: 'De btw is niet aftrekbaar',
//           },
//           {
//             id: '9e29fcbf-5b1e-4831-a76f-151d5897bde2',
//             title: 'De btw is volledig aftrekbaar',
//           },
//           {
//             id: '0a164e6d-9cbe-4db4-8bf8-707e33fa87bb',
//             title: 'We vragen een nieuwe factuur, waarop btw wordt aangerekend',
//           },
//           {
//             id: '752e636b-4b1a-4917-8d83-193cb22b7d43',
//             title: 'De btw is aftrekbaar volgens het verhoudingsgetal',
//           },
//         ],
//       },
//       {
//         id: '14be1021-4b0f-4f30-b229-61bab5f555e0',
//         title:
//           'Conessence heeft in 2010 een personenwagen aangekocht t.w.v. 30.000,00 EUR + 6.300,00 EUR btw. Bij de aankoop werd er 50% van de btw in aftrek gebracht. In 2020 beslist Conessence om deze personenwagen te verkopen aan een particulier. Ze zijn hierbij een prijs overeengekomen van 8.000,00 EUR excl. btw. De verkoopprijs incl. btw bedraagt:',
//         answers: [
//           {
//             id: '4d9cedbe-854c-485e-a368-a33e084ec8c1',
//             title: '8.000,00 EUR',
//           },
//           {
//             id: '4e09abab-f1d5-4315-9d9d-869b71afe71c',
//             title: '8.840,00 EUR',
//           },
//           {
//             id: '3d8e1c59-604c-427a-862e-c00057a70f0f',
//             title: '8.480,00 EUR',
//           },
//         ],
//       },
//       {
//         id: '1689b296-b8fb-4b8f-b928-04097944d085',
//         title:
//           'Begin januari heeft Conessence enkele flessen wijn aangekocht om cadeau te doen aan zijn klanten. De kostprijs voor één fles is 22,00 EUR excl. btw, maar ze hebben gevraagd deze in een cadeauverpakking te doen van 2 stuks per pakket.',
//         answers: [
//           {
//             id: '6cb3bfc7-dd7f-4ac8-8ec7-26b1dd655d4a',
//             title: 'De btw is niet aftrekbaar',
//           },
//           {
//             id: '52da8356-6bb3-4f54-83fe-6bef089b1870',
//             title: 'De btw is 100% aftrekbaar',
//           },
//         ],
//       },
//       {
//         id: 'fd806440-d50c-4ae9-a30c-e55ef0641e12',
//         title: 'Niet-aftrekbare btw in een vennootschap is:',
//         answers: [
//           {
//             id: 'b341ea6d-acc0-47bc-877d-b6f0861e05cb',
//             title: 'Een schuld aan de btw-administratie',
//           },
//           {
//             id: 'be4a9915-4d92-49f3-acae-6026eee8fe4c',
//             title: 'Een kost',
//           },
//           {
//             id: '3d023450-d745-44da-adeb-5325580c889a',
//             title: 'Een schuld',
//           },
//           {
//             id: 'de7acf02-e511-4266-bfc9-1c466ec0dc93',
//             title: 'Een verworpen uitgaven',
//           },
//         ],
//       },
//       {
//         id: '20562a6e-8ce6-4305-807b-301262e641b7',
//         title: 'Wat betekent het als de R/C debet staat?',
//         answers: [
//           {
//             id: '571401c4-ade7-4c4a-8e76-d22837c7e489',
//             title: 'De vennootschap heeft een vordering ten opzichte van de zaakvoerder',
//           },
//           {
//             id: '6175bd2f-f0fd-450b-8951-35327b3328f2',
//             title: 'De vennootschap heeft een schuld ten opzichte van de zaakvoeder',
//           },
//           {
//             id: '568ebd3b-5906-4043-bbaa-f05b7d8af7f7',
//             title: 'De zaakvoerder koopt aandelen in het bedrijf',
//           },
//           {
//             id: '6118918d-f41d-4df1-9b4f-57c9646c1877',
//             title: 'Geen enkele uitspraak is juist',
//           },
//         ],
//       },
//       {
//         id: 'a6259743-23d7-4140-ae56-cea4eaaa6831',
//         title: 'Wat hoort niet thuis op de belastingaangifte?',
//         answers: [
//           {
//             id: '56dd9cb9-04ff-4e5a-881d-356b5fe6e791',
//             title: 'Overgedragen verliezen',
//           },
//           {
//             id: 'a28a1835-2c01-450f-98b8-2bbb26d9b16c',
//             title: 'Investeringskrediet',
//           },
//           {
//             id: '3bf35c95-1052-4c4c-92b7-cfc76da399c6',
//             title: 'Werkgeversbijdrage RSZ',
//           },
//         ],
//       },
//       {
//         id: '6e26308d-11ce-4ad5-8881-0b83cbf780f3',
//         title:
//           'Conessence doet een beroep op een IT-consultant in Zwitserland. Moet bij de btw-aangifte de btw verlegd worden?',
//         answers: [
//           {
//             id: 'd9521cde-ce90-4443-8492-1a69957ca6e5',
//             title: 'Ja',
//           },
//           {
//             id: '422fd5cb-5a46-4620-88e1-da4aeb3cc584',
//             title: 'Neen',
//           },
//         ],
//       },
//       {
//         id: 'e3c16daa-3801-4b6d-b217-e28df8afe1a7',
//         title:
//           'Conessence geeft een relatiegeschenk aan een klant van 150,00 EUR. Er werd geen fiche opgemaakt. Is deze kost aftrekbaar?',
//         answers: [
//           {
//             id: 'e0a23d62-dc73-4a3a-ad70-99bbf78921c5',
//             title: 'Ja',
//           },
//           {
//             id: '6502fa59-be8b-40d1-ac6c-5c26e1a9bc45',
//             title: 'Neen',
//           },
//         ],
//       },
//       {
//         id: '8d4416a8-5817-4630-8244-c9679aafdc4d',
//         title: 'Welke uitspraak is juist?',
//         answers: [
//           {
//             id: '255083c7-46bb-4580-b1c9-4cc1a0daaf6c',
//             title:
//               'De rekening voorraadwijzigingen is een kostenrekening en wordt gecrediteerd bij een afname van de voorraad',
//           },
//           {
//             id: 'fa4fce1d-fa0a-4ea3-9fef-f49118a1a8c9',
//             title:
//               'De rekening voorraadwijzigingen is een kostenrekening en wordt gedebiteerd bij een afname van de voorraad',
//           },
//           {
//             id: 'bbf915f5-bc26-4148-a726-82d25dddfa1c',
//             title:
//               'De rekening voorraadwijzigingen is een kostenrekening en wordt gedebiteerd bij een stijging van de voorraad',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Kennis boekingen – allemaal binnen de Belgische grenzen',
//     questions: [
//       {
//         id: '4bfaf28f-9230-4d49-a344-4ae7eead8518',
//         title:
//           'Een groothandel in informaticamateriaal verkoopt voor 70.000,00 EUR (excl. btw) software aan een klant. Wat is de correcte boekingswijze?',
//         answers: [
//           {
//             id: 'cb75f130-486e-4bc5-b567-fa7344043c09',
//             title:
//               '40xxxx Handelsdebiteuren (Debet) voor 84.700,00 EUR aan 70xxxx verkopen (Credit) voor 84.700,00 EUR',
//             titleHtml:
//               '40xxxx Handelsdebiteuren <strong>(Debet)</strong> voor 84.700,00 EUR aan 70xxxx verkopen <strong>(Credit)</strong> voor 84.700,00 EUR',
//           },
//           {
//             id: '26835d24-d4bf-4fbf-b3b0-b379d5a55590',
//             title:
//               '40xxxx Handelsdebiteuren (Credit) voor 84.700,00 EUR aan 70xxxx verkopen (Debet) voor 70.000,00 EUR en 49xxxx btw op verkopen (Debet) voor 14.700,00 EUR',
//             titleHtml:
//               '40xxxx Handelsdebiteuren <strong>(Credit)</strong> voor 84.700,00 EUR aan 70xxxx verkopen <strong>(Debet)</strong> voor 70.000,00 EUR en 49xxxx btw op verkopen <strong>(Debet)</strong> voor 14.700,00 EUR',
//           },
//           {
//             id: '46b5844e-8483-41c8-a623-2d3e56bbeb5f',
//             title:
//               '40xxxx Handelsdebiteuren (Debet) voor 84.700,00 EUR aan 70xxxx verkopen (Credit) voor 70.000,00 EUR en 49xxxx btw op verkopen (Credit) voor 14.700,00 EUR',
//             titleHtml:
//               '40xxxx Handelsdebiteuren <strong>(Debet)</strong> voor 84.700,00 EUR aan 70xxxx verkopen <strong>(Credit)</strong> voor 70.000,00 EUR en 49xxxx btw op verkopen <strong>(Credit)</strong> voor 14.700,00 EUR',
//           },
//         ],
//       },
//       {
//         id: 'cfc051df-576e-4c4e-b136-bee6decf758f',
//         title:
//           'Conessence koopt voor 4.000,00 EUR (excl. btw) mondmaskers aan bij een onderneming in Lievegem. Wat is de correcte boekingswijze?',
//         answers: [
//           {
//             id: '37ed2f00-6324-45d6-bcc5-e4e7e2d271bd',
//             title:
//               '44xxxx Leverancier (Credit) voor 4.840,00 EUR aan 61xxxx Diverse goederen (Debet) voor 4.000,00 EUR en 41xxxx Aftrekbare btw (Debet) voor 840,00 EUR',
//             titleHtml:
//               '44xxxx Leverancier <strong>(Credit)</strong> voor 4.840,00 EUR aan 61xxxx Diverse goederen <strong>(Debet)</strong> voor 4.000,00 EUR en 41xxxx Aftrekbare btw <strong>(Debet)</strong> voor 840,00 EUR',
//           },
//           {
//             id: '3833af05-a2e7-454d-a095-f2fc06ba4201',
//             title:
//               '61xxxx Handelsdebiteuren (Debet) voor 4.840,00 EUR aan 44xxxx leverancier (Credit) voor 4.840,00 EUR',
//             titleHtml:
//               '61xxxx Handelsdebiteuren <strong>(Debet)</strong> voor 4.840,00 EUR aan 44xxxx leverancier <strong>(Credit)</strong> voor 4.840,00 EUR',
//           },
//           {
//             id: '08642b85-c01c-4244-8164-6f13171fdc7c',
//             title:
//               '44xxxx Leveranciers (Credit) voor 4.840,00 EUR aan 24xxxx Aankoop bureaumateriaal (Debet) voor 4.000,00 EUR en 41xxxx Aftrekbare btw (Debet) voor 840,00 EUR',
//             titleHtml:
//               '44xxxx Leveranciers <strong>(Credit)</strong> voor 4.840,00 EUR aan 24xxxx Aankoop bureaumateriaal <strong>(Debet)</strong> voor 4.000,00 EUR en 41xxxx Aftrekbare btw <strong>(Debet)</strong> voor 840,00 EUR',
//           },
//         ],
//       },
//       {
//         id: 'ca3f23ba-4f32-4986-8fac-59f4b9947353',
//         title:
//           'Conessence krijgt een creditnota van 400,00 EUR (excl. btw) op de aankoop van de mondmaskers bij de onderneming in Lievegem. Wat is de correcte boekingswijze?',
//         answers: [
//           {
//             id: '907411e7-990b-4983-843d-eba90c30794f',
//             title:
//               '44xxxx Leverancier (Debet) voor 484,00 EUR aan 61xxxx Diverse goederen (Credit) voor 400,00 EUR en 49xxxx btw op creditnota (Credit) voor 84,00 EUR',
//             titleHtml:
//               '44xxxx Leverancier <strong>(Debet)</strong> voor 484,00 EUR aan 61xxxx Diverse goederen <strong>(Credit)</strong> voor 400,00 EUR en 49xxxx btw op creditnota <strong>(Credit)</strong> voor 84,00 EUR',
//           },
//           {
//             id: 'a8ad7da0-7c37-4e92-b6ca-45d43af8edf0',
//             title: 'Je past het bedrag op de oorspronkelijke factuur aan',
//           },
//           {
//             id: 'f79b9d23-08ea-416e-ac7f-cf7c2064e5e8',
//             title:
//               '44xxxx Leverancier (Debet) voor 400,00 EUR aan 61xxxx Diverse goederen (Credit) voor 400,00 EUR',
//             titleHtml:
//               '44xxxx Leverancier <strong>(Debet)</strong> voor 400,00 EUR aan 61xxxx Diverse goederen <strong>(Credit)</strong> voor 400,00 EUR',
//           },
//         ],
//       },
//       {
//         id: '76508143-9077-491f-a9c9-8014c66cdacd',
//         title:
//           'Conessence gaat met al hun medewerkers gaan eten op restaurant om het jaar mooi af te sluiten. De rekening bedraagt 726,00 EUR inclusief btw. Wat is de correcte boekingswijze?',
//         answers: [
//           {
//             id: '5983fed4-fba0-4c54-9347-a2c9d0cb1d49',
//             title:
//               '61XXXX Restaurantkosten (Debet) voor 726,00 EUR aan 44xxxx leverancier (Credit) voor 726,00 EUR',
//             titleHtml:
//               '61XXXX Restaurantkosten <strong>(Debet)</strong> voor 726,00 EUR aan 44xxxx leverancier <strong>(Credit)</strong> voor 726,00 EUR',
//           },
//           {
//             id: 'd14634c6-1e3a-47a6-bb4d-06dd7cebbd75',
//             title:
//               '61xxxx Restaurantkosten (Debet) voor 600,00 EUR en 41xxxx Aftrekbare btw (Debet) voor 126,00 EUR aan 44xxxx Leverancier (Credit) voor 726,00 EUR',
//             titleHtml:
//               '61xxxx Restaurantkosten <strong>(Debet)</strong> voor 600,00 EUR en 41xxxx Aftrekbare btw <strong>(Debet)</strong> voor 126,00 EUR aan 44xxxx Leverancier <strong>(Credit)</strong> voor 726,00 EUR',
//           },
//           {
//             id: 'cae587f2-2660-40bd-a259-08fb58f7f2b9',
//             title: 'Geen van beide is correct',
//           },
//         ],
//       },
//       {
//         id: 'd1b7739c-abad-4be2-a88a-5d1fddafcb5b',
//         title:
//           'Tijdens het vorig boekjaar heeft Conessence 50.000,00 EUR geboekt als ‘geraamde belastingen’. De ingekohierde belasting bedraagt echter 55.000,00 EUR. Wat is de correcte boekingswijze?',
//         answers: [
//           {
//             id: 'fffd4778-d583-41b7-9cf3-c53355c37ef5',
//             title:
//               '67xxxx Verschuldigde belastingsupplementen (Debet) voor 5.000,00 EUR aan 55xxxx Bankinstelling (Credit) voor 5.000,00 EUR',
//             titleHtml:
//               '67xxxx Verschuldigde belastingsupplementen <strong>(Debet)</strong> voor 5.000,00 EUR aan 55xxxx Bankinstelling <strong>(Credit)</strong> voor 5.000,00 EUR',
//           },
//           {
//             id: '706be606-8a17-4d66-960f-a8b9095a28eb',
//             title:
//               '45xxxx Geraamd bedrag van de belastingschuld (Debet) voor 50.000,00 EUR en 67xxxx verschuldigde belastingsupplementen (Debet) voor 5.000,00 EUR aan 45xxxx Te betalen belastingen (Credit) voor 55.000,00 EUR',
//             titleHtml:
//               '45xxxx Geraamd bedrag van de belastingschuld <strong>(Debet)</strong> voor 50.000,00 EUR en 67xxxx verschuldigde belastingsupplementen <strong>(Debet)</strong> voor 5.000,00 EUR aan 45xxxx Te betalen belastingen <strong>(Credit)</strong> voor 55.000,00 EUR',
//           },
//           {
//             id: 'b51286cc-5fba-49eb-beae-3a1e4ec8a1a1',
//             title: 'Geen van beide is correct',
//           },
//         ],
//       },
//       {
//         id: '9924a48c-d259-476b-80f7-a2d2030ea240',
//         title:
//           'Een productiebedrijf stelt op het einde van het jaar een voorraadvermindering vast. De beginvoorraad handelsgoederen bedroeg 50.000,00 EUR en de eindvoorraad handelsgoederen bedraagt 45.000,00 EUR. Wat is de correcte boekingswijze?',
//         answers: [
//           {
//             id: '7b02b794-a98f-4b09-9088-20814dd45cd0',
//             title:
//               '60xxxx Voorraadwijziging handelsgoederen (Debet) voor 5.000,00 EUR aan 34xxxx Voorraad handelsgoederen (Credit) voor 5.000,00 EUR',
//             titleHtml:
//               '60xxxx Voorraadwijziging handelsgoederen <strong>(Debet)</strong> voor 5.000,00 EUR aan 34xxxx Voorraad handelsgoederen <strong>(Credit)</strong> voor 5.000,00 EUR',
//           },
//           {
//             id: '898083e7-a311-4ac5-9f77-04ca9d95404b',
//             title:
//               '60xxxx Voorraadwijziging handelsgoederen (Credit) voor 5.000,00 EUR aan 34xxxx Voorraad handelsgoederen (Debet) voor 5.000,00 EUR',
//             titleHtml:
//               '60xxxx Voorraadwijziging handelsgoederen <strong>(Credit)</strong> voor 5.000,00 EUR aan 34xxxx Voorraad handelsgoederen <strong>(Debet)</strong> voor 5.000,00 EUR',
//           },
//           {
//             id: '04d89561-c690-464b-800a-560451e87293',
//             title: 'Een voorraadvermindering hoeft niet geboekt te worden',
//           },
//         ],
//       },
//       {
//         id: '91a7808f-e70e-47d8-b1b4-8054ac39f5ce',
//         title:
//           'Conessence boekt een winst van het boekjaar van 50.000,00 EUR. Het overgedragen verlies van vorig boekjaar bedraagt 10.000,00 EUR. De resultaatverwerking is als volgt: Toevoeging aan de wettelijke reserves 10.000,00 EUR en over te dragen winst 30.000,00 EUR. Wat is de correcte boekingswijze?',
//         answers: [
//           {
//             id: 'fe4b4e8f-bf63-4cf7-a710-f24139bdbf11',
//             title:
//               '69xxxx Overgedragen verlies vorig boekjaar (Debet) voor 10.000,00 EUR Aan 14xxxx Overgedragen verlies (Credit) voor 10.000,00 EUR69xxxx Toevoeging aan de wettelijke reserves (Debet) voor 10.000,00 EUR 69xxxx Over te dragen winst (Debet) voor 30.000,00 EUR Aan 13xxxx Wettelijke reserves (Credit) voor 10.000,00 EUR 14xxxx Overgedragen winst (Credit) voor 30.000,00 EUR.',
//             titleHtml:
//               '69xxxx Overgedragen verlies vorig boekjaar <strong>(Debet)</strong> voor 10.000,00 EUR <span style="display: block; padding-left: 30px; margin-bottom: 10px">Aan 14xxxx Overgedragen verlies <strong>(Credit)</strong> voor 10.000,00 EUR</span>69xxxx Toevoeging aan de wettelijke reserves <strong>(Debet)</strong> voor 10.000,00 EUR<br />69xxxx Over te dragen winst <strong>(Debet)</strong> voor 30.000,00 EUR <span style="display: block; padding-left: 30px;">Aan 13xxxx Wettelijke reserves <strong>(Credit)</strong> voor 10.000,00 EUR</span><span style="display: block; padding-left: 62px; margin-bottom: 10px">14xxxx Overgedragen winst <strong>(Credit)</strong> voor 30.000,00 EUR.</span>',
//           },
//           {
//             id: '37543e64-d28d-429d-b20a-2e4cf7eedef8',
//             title:
//               '14xxxx Overgedragen verlies (Debet) voor 10.000,00 EUR Aan 69xxxx Overgedragen verlies vorig boekjaar (Credit) voor 10.000,00 EUR69xxxx Toevoeging aan de wettelijke reserves (Debet) voor 10.000,00 EUR 69xxxx Over te dragen winst (Debet) voor 30.000,00 EUR Aan 13xxxx Wettelijke reserves (Credit) voor 10.000,00 EUR 14xxxx Overgedragen winst (Credit) voor 30.000,00 EUR.',
//             titleHtml:
//               '14xxxx Overgedragen verlies <strong>(Debet)</strong> voor 10.000,00 EUR<span style="display: block; padding-left: 30px; margin-bottom: 10px">Aan 69xxxx Overgedragen verlies vorig boekjaar <strong>(Credit)</strong> voor 10.000,00 EUR</span>69xxxx Toevoeging aan de wettelijke reserves <strong>(Debet)</strong> voor 10.000,00 EUR<br />69xxxx Over te dragen winst <strong>(Debet)</strong> voor 30.000,00 EUR<span style="display: block; padding-left: 30px;">Aan 13xxxx Wettelijke reserves <strong>(Credit)</strong> voor 10.000,00 EUR</span><span style="display: block; padding-left: 62px; margin-bottom: 10px">14xxxx Overgedragen winst <strong>(Credit)</strong> voor 30.000,00 EUR.</span>',
//           },
//           {
//             id: 'f49ddf71-de8d-47ca-9cc1-31317b50bab9',
//             title:
//               '14xxxx Overgedragen verlies (Debet) voor 10.000,00 EUR Aan 69xxxx Overgedragen verlies vorig boekjaar (Credit) voor 10.000,00 EUR13xxxx Wettelijke reserves (Debet) voor 10.000,00 EUR14xxxx Overgedragen winst (Debet) voor 30.000,00 EUR Aan 69xxxx Toevoeging aan de wettelijke reserves (Credit) voor 10.000,00 EUR 69xxxx Over te dragen winst (Credit) voor 30.000,00 EUR',
//             titleHtml:
//               '14xxxx Overgedragen verlies <strong>(Debet)</strong> voor 10.000,00 EUR<span style="display: block; padding-left: 30px; margin-bottom: 10px">Aan 69xxxx Overgedragen verlies vorig boekjaar <strong>(Credit)</strong> voor 10.000,00 EUR</span>13xxxx Wettelijke reserves <strong>(Debet)</strong> voor 10.000,00 EUR<br />14xxxx Overgedragen winst <strong>(Debet)</strong> voor 30.000,00 EUR<span style="display: block; padding-left: 30px;">Aan 69xxxx Toevoeging aan de wettelijke reserves <strong>(Credit)</strong> voor 10.000,00 EUR</span><span style="display: block; padding-left: 62px;">69xxxx Over te dragen winst <strong>(Credit)</strong> voor 30.000,00 EUR</span>',
//           },
//         ],
//       },
//     ],
//   },
// ];

interface SubmitValues {
  [key: string]: string;
}

function TestPart({ onSubmit, testData }: { onSubmit: any; testData: TestList[] }) {
  const [currentPart, setCurrentPart] = useState(0);
  const [answers, setAnswers] = useState<SubmitValues>({});

  const schema = yup.object().shape(
    testData[currentPart].questions.reduce((allQuestions, question) => {
      return {
        ...allQuestions,
        [question.id]: yup.string().required('Kies een antwoord.'),
      };
    }, {})
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPart]);

  return (
    <>
      <h3>
        Deel {currentPart + 1}: {testData[currentPart].title}
      </h3>
      <Formik
        key={currentPart}
        validationSchema={schema}
        initialValues={answers}
        onSubmit={async (values: SubmitValues) => {
          const updatedAnswers = {
            ...answers,
            ...values,
          };
          setAnswers(updatedAnswers);
          if (currentPart >= testData.length - 1) {
            // Submit test
            await onSubmit(updatedAnswers);
          } else {
            setCurrentPart(currentPart + 1);
          }
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ handleSubmit, handleChange, values, errors, isSubmitting }) => (
          <Form noValidate onSubmit={handleSubmit}>
            {testData[currentPart].questions.map((question, index) => {
              return (
                <>
                  <Form.Group
                    controlId={question.id}
                    key={question.id}
                    style={{ margin: '35px 0' }}
                  >
                    <Form.Label>
                      <span
                        style={{
                          fontWeight: 'bold',
                          ...(!!errors[question.id] ? { color: '#dc3545' } : {}),
                        }}
                      >
                        {index + 1}. {question.title}
                      </span>
                    </Form.Label>
                    {question.answers.map((answer) => {
                      return (
                        <Form.Check
                          style={{ margin: '5px 0' }}
                          key={answer.id}
                          type="radio"
                          label={
                            <div
                              dangerouslySetInnerHTML={{ __html: answer.titleHtml || answer.title }}
                            />
                          }
                          name={question.id}
                          id={answer.id}
                          value={answer.id}
                          onChange={handleChange}
                          checked={values[question.id] === answer.id}
                        />
                      );
                    })}
                  </Form.Group>
                </>
              );
            })}
            <div className="d-flex justify-content-between">
              {currentPart > 0 ? (
                <Button
                  onClick={() => {
                    setAnswers({
                      ...answers,
                      ...values,
                    });
                    setCurrentPart(currentPart - 1);
                  }}
                  disabled={isSubmitting}
                >
                  Vorige
                </Button>
              ) : (
                <div />
              )}
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting && (
                  <span
                    style={{ marginRight: '10px' }}
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                <span>{currentPart >= testData.length - 1 ? 'Test indienen' : 'Volgende'}</span>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default TestPart;
