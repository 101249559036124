import React, { useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';

import { Button, Spinner } from 'react-bootstrap';

import Test from './Test';

export const STATUS_ID_TEST = '11290';
export const STATUS_ID_GDPR = '11291';
export const STATUS_ID_SUBMITTED = '11292';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQueryParameters() {
  return new URLSearchParams(useLocation().search);
}

const API_HOST = process.env.REACT_APP_API_HOST;
async function getTest(candidateId: string, testId: string) {
  const { data } = await axios.get(`${API_HOST}/${candidateId}/${testId}`);
  return data;
}

function useTest(candidateId: string | null, testId: string | null) {
  return useQuery([candidateId, testId], getTest, {
    enabled: candidateId && testId,
    retry: false,
  });
}

function Start() {
  const query = useQueryParameters();
  const candidateId = query.get('c');
  const testId = query.get('t');
  const { data, error, isLoading, isIdle, refetch } = useTest(candidateId, testId);

  const [testStarted, setTestStarted] = useState(false);

  const getWelcomeText = () => {
    if (data.statusId === STATUS_ID_TEST) {
      return (
        <>
          <p>
            Aansluitend op het gesprek met één van onze consultants, volgt hierbij de boekhoudtest.
          </p>
          <p>
            De boekhoudtest zal ongeveer 30 minuten van uw tijd in beslag nemen. De test wordt niet
            getimed en je kan te allen tijde terugkeren naar de vorige pagina voor eventuele
            correcties. De boekhoudtest bestaat uit 3 delen: aftrekbare btw, algemene boekhouding en
            kennis boekingen.
          </p>
          <p>
            Alvorens van start te gaan met de test wordt op de volgende pagina gevraagd om het
            toestemmingsformulier voor het gebruik van persoonlijke gegevens in te vullen.
          </p>
          <p>Veel succes!</p>
          <p>Team Conessence</p>
        </>
      );
    }

    return 'Welkom dummy text GDPR';
  };

  const getDoneText = () => {
    return (
      <>
        <p>Bedankt!</p>
        <p>Uw gegevens worden verwerkt.</p>
        <p>
          Blijf op de hoogte van alle vacatures via onze{' '}
          <a href="https://www.conessence.com" rel="noopener noreferrer" target="_blank">
            website
          </a>
          .
        </p>
        <p>
          Blijf op de hoogte van updates via{' '}
          <a
            href="https://www.linkedin.com/company/conessence"
            rel="noopener noreferrer"
            target="_blank"
          >
            LinkedIn
          </a>
          .
        </p>
        <p>Team Conessence</p>
      </>
    );
  };

  return (
    <>
      {isIdle ? (
        'Ongeldige link'
      ) : error ? (
        'Niet gevonden'
      ) : isLoading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : data.statusId === STATUS_ID_SUBMITTED ? (
        <p>{getDoneText()}</p>
      ) : !testStarted && data.statusId === STATUS_ID_TEST ? (
        <>
          <h3>
            Beste {data.firstName} {data.lastName}
          </h3>
          {getWelcomeText()}
          <Button type="primary" className="mt-4" onClick={() => setTestStarted(true)}>
            Ga van start
          </Button>
        </>
      ) : (
        <Test data={data} refetch={refetch} />
      )}
    </>
  );
}

export default Start;
